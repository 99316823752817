import React, { Component } from "react";

const initData = {
  heading: "Our Reviews",
  content:
    "Check out what our happy customers are saying about Custommax App",
};

const person = [
  {
    id: 1,
    name: "Comrades Ltd",
    designation: "",
    image: "",
    openion:
      "                                            Super impressed with the customer service for this app. This app has done everything I needed it to do.  I know the app is exactly what I've been looking for.                                                                                                  ",
  },
  {
    id: 2,
    name: "Kmedia Company",
    designation: "",
    image: "",
    openion:
      "The app is so wonderful, it’s really easy to use. All of the cliparts and libraries are available and you just need a few mins to add them to your product. It helps me save so much time. I do not see any trouble or difficulty while using this app. Highly recommend all of you try using Custommax 😉",
  },
  {
    id: 3,
    name: "X-express Team",
    designation: "",
    image: "",
    openion:
      "            This is the first time I have used a customized app like this, at first I was unfamiliar with the options, but I was helped by the customer support team of Custommax. They’re all so nice and friendly to me. Thank you so much for helping me build my website!!!                              ",
  },
  {
    id: 4,
    name: "HK99 Team",
    designation: "",
    image: "",
    openion:
      "        I love this app, in the previous app I use, when I exported my orders, I had to do in a very long time, but with Custommax, the app helps me to export CSV files, I have everything about customers (email, phone, shipping name, design link, etc…). Everything becomes so easy and convenient.      ",
  },
  {
    id: 4,
    name: "Pedro Rafael",
    designation: "",
    image: "",
    openion:
      "                                 Custommax helps me not waste so much time in creating products, the function of duplicating products helps me a lot. 3s for a copied product, I’m so satisfied with this app.                                                                                              ",
  },
];

class Testimonial extends Component {
  state = {
    data: {},
    person: [],
  };

  componentDidMount() {
    // you can also make this by api call
    // axios.get(`${BASE_URL}`)
    //     .then(res => {
    //         this.setState({
    //             data: res.data,
    //             person: res.data.person
    //         })
    //         console.log(this.state)
    //     })
    //     .catch(err => console.log(err))

    this.setState({
      data: initData,
      person: person,
    });
  }

  render() {
    return (
      <section className="testimonial-section bg-secondary ptb-90">
        <div className="testimonial-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading text-center">
                  <h2>{this.state.data.heading}</h2>
                  <h5>{this.state.data.content}</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="owl-carousel owl-theme testimonial-slider">
                  {this.state.person.map((item, idx) => {
                    return (
                      <div key={`t_${idx}`} className="item">
                        <div className="single-testimonial-item">
                          <span className="blockquote-icon">
                            <i className="fa fa-quote-left"></i>
                          </span>
                          <div className="testimonial-content">
                            <p className="">{item.openion}</p>
                          </div>
                          <div className="testimonial-author">
                            {/* <img src={item.image} alt="" /> */}
                            <div className="testimonial-author-info">
                              <h6>{item.name}</h6>
                              {/* <span>{item.designation}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonial;
