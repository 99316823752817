import React, { Component } from "react";
import axios from "axios";

let bg = "";
let bgCss = "no-repeat center center fixed";
let bgStyle = {
  background: "",
};

// const BASE_URL =
//   "https://my-json-server.typicode.com/lhmisho/React-Tutorial/themeOneDownloadSection";

class Download extends Component {
  state = {
    initData: {
      heading: "Get The App Now !",
      content:
        "You can set up your app here or get a free demo of all Custommax's Features with your items. Moreover, you also can get a one-on-one walkthrough of Custommax's core capabilities and understand how it may help you grow revenue.",
      image: "/img/download-app.png",
    },
    data: [
      {
        id: 1,
        heaing: "Install now on",
        category: "Shopify",
        iconClass: "",
        extraaClass: "hover-active",
        link:"https://apps.shopify.com/custommax?surface_detail=Custom+max&surface_inter_position=1&surface_intra_position=4&surface_type=search",
      },
      {
        id: 2,
        heaing: "View on",
        category: "Shopify",
        iconClass: "",
        extraaClass: "",
        link:"https://mtee-2.myshopify.com/collections/sample-products",
      },
      {
        id: 3,
        heaing: "Request A Demo",
        category: "Shopify",
        iconClass: "",
        extraaClass: "hover-active",
        link:"#contact",
      },
    ],
  };
  // componentDidMount() {
  //   axios
  //     .get(`${BASE_URL}`)
  //     .then((res) => {
  //       this.setState({
  //         initData: res.data,
  //         data: res.data.data,
  //       });
  //       // console.log(this.state)
  //     })
  //     .catch((err) => console.log(err));
  // }

  render() {
    return (
      <section className="download-section" style={bgStyle}>
        <div className="container">
          <div className="row">
            {/* <div className="col-md-5 hidden-sm">
              <div className="download-app-img">
                <img
                  src={this.state.initData.image}
                  alt="app download"
                  className="img-responsive"
                />
              </div>
            </div> */}
            <div className="col-sm-12">
              <div
                className="download-app-text"
                style={{ textAlignVertical: "center", textAlign: "center" }}
              >
                <h3>{this.state.initData.heading}</h3>
                <p>{this.state.initData.content}</p>
                <div className="download-app-button">
                  {this.state.data.map((item, idx) => {
                    return (
                      <a
                        key={`dw_${idx}`}
                        href={item.link}
                        className={`download-btn ${item.extraaClass}`}
                      >
                        <span className={item.iconClass}></span>
                        <p>
                          <small>{item.heaing}</small>
                          <br /> {item.category}
                        </p>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Download;
