import React from "react";

// const BASE_URL = "https://my-json-server.typicode.com/lhmisho/jsonserver/themeOnePricingSection";
class Pricing extends React.Component {
  state = {
    data: {},
    pricingData: [
      {
        id: 1,
        heading: "Starter",
        priceType: "Starter",
        minPrice: "0.",
        subPrice: "00",
        packageTime: "monthly",
        color: "burlywood",
      },
      {
        id: 2,
        heading: "Basic",
        priceType: "Basic",
        minPrice: "14.",
        subPrice: "99 + 2% revenue",
        packageTime: "monthly",
        color: "lightpink",
      },
      {
        id: 3,
        heading: "Advance",
        priceType: "Advance",
        minPrice: "39.",
        subPrice: "99 + 1% revenue",
        packageTime: "monthly",
        color: "blueviolet",
      },
      {
        id: 4,
        heading: "Premium",
        priceType: "Premium",
        minPrice: "129.",
        subPrice: "99 + 0.5% revenue",
        packageTime: "monthly",
        color: "aquamarine",
      },
      {
        id: 5,
        heading: "Enterprise",
        priceType: "Enterprise",
        minPrice: "Contact with Custom Max Supporter",
        subPrice: "",
        packageTime: "monthly",
        color: "orange",
      },
    ],
  };

  // componentDidMount(){
  //     axios.get(`${BASE_URL}`)
  //         .then(res => {
  //             this.setState({
  //                 data: res.data,
  //                 pricingData: res.data.pricingData
  //             })
  //             // console.log(this.state)
  //         })
  //         .catch(err => console.log(err))
  // }

  render() {
    return (
      <div>
        {/* start pricing */}
        <section id="pricing" className="pricing-section ptb-90">
          <div className="pricing-section-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading text-center">
                    <h3>{this.state.data.heading}</h3>
                    <p>{this.state.data.content}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="bg-color-sky-light">
                  <div className="content-md container">
                    <div className="row">
                      {this.state.pricingData.map((item, idx) => {
                        return (
                          <div key={`pricing_${idx}`} className="col-md-4">
                            {/* start pricing single */}
                            <div className="pricing-single bg-secondary">
                              <div style={{ backgroundColor: item.color }}>
                                <div className="pricing-single-header">
                                  <h4 style={{ color: "white" }}>
                                    {item.heading}
                                  </h4>
                                  <span>{item.type}</span>
                                </div>
                              </div>
                              {(() => {
                                switch (item.heading) {
                                  case "Enterprise":
                                    return (
                                      <div
                                        className="pricing-single-content"
                                        style={{ marginRight: "20px" }}
                                      >
                                        <div className="mb-40">
                                          <span
                                            style={{
                                              fontSize: "24px",
                                              color: "orange",
                                            }}
                                          >
                                            {item.minPrice}
                                          </span>
                                        </div>
                                        <div className="center-block">
                                          <button
                                            type="button"
                                            className="softo-btn-outline"
                                          >
                                            <a href="#contact">Contact</a>
                                          </button>
                                        </div>
                                        <div
                                          className="mb-40"
                                          style={{
                                            marginTop: "10px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <ul>
                                            <li>
                                              <span>{"\u2022"}</span> Priority
                                              to Support{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Customized
                                              Intergration (coming soon){" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Request
                                              for Custom Features
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Negotiable
                                              Price
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Unlimited
                                              Products per Month
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  case "Basic":
                                    return (
                                      <div className="pricing-single-content">
                                        <div className="mb-40">
                                          <span className="price-icon-sign">
                                            <i className="fa fa-dollar"></i>
                                          </span>
                                          <span className="main-price">
                                            {item.minPrice}
                                          </span>
                                          <span className="sub-price">
                                            {item.subPrice}
                                          </span>
                                          <span className="main-price-info">
                                            {item.packageTime}
                                          </span>
                                        </div>
                                        <div className="center-block">
                                          <a
                                            type="button"
                                            className="softo-btn-outline"
                                            href="https://app.custommax.net/pricing"
                                          >
                                            Buy Now
                                          </a>
                                        </div>
                                        <div
                                          className="mb-40"
                                          style={{
                                            marginTop: "10px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <ul>
                                            <li>
                                              <span>{"\u2022"}</span> 24/7 Tech
                                              Support{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Standard
                                              Preview Tool{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Upto 500
                                              Products per Month{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  case "Advance":
                                    return (
                                      <div
                                        className="pricing-single-content"
                                        style={{ marginRight: "20px" }}
                                      >
                                        <div className="mb-40">
                                          <span className="price-icon-sign">
                                            <i className="fa fa-dollar"></i>
                                          </span>
                                          <span className="main-price">
                                            {item.minPrice}
                                          </span>
                                          <span className="sub-price">
                                            {item.subPrice}
                                          </span>
                                          <span className="main-price-info">
                                            {item.packageTime}
                                          </span>
                                        </div>
                                        <div className="center-block">
                                          <a
                                            type="button"
                                            className="softo-btn-outline"
                                            href="https://app.custommax.net/pricing"
                                          >
                                            Buy Now
                                          </a>
                                        </div>
                                        <div
                                          className="mb-40"
                                          style={{
                                            marginTop: "10px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <ul>
                                            <li>
                                              <span>{"\u2022"}</span> 24/7 Tech
                                              Support
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Basic Plan {" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span>{" "}
                                              Duplicate Product{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Upto 1000 Products per Month{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  case "Premium":
                                    return (
                                      <div className="pricing-single-content">
                                        <div className="mb-40">
                                          <span className="price-icon-sign">
                                            <i className="fa fa-dollar"></i>
                                          </span>
                                          <span className="main-price">
                                            {item.minPrice}
                                          </span>
                                          <span className="sub-price">
                                            {item.subPrice}
                                          </span>
                                          <span className="main-price-info">
                                            {item.packageTime}
                                          </span>
                                        </div>
                                        <div className="center-block">
                                          <a
                                            type="button"
                                            className="softo-btn-outline"
                                            href="https://app.custommax.net/pricing"
                                          >
                                            Buy Now
                                          </a>
                                        </div>
                                        <div
                                          className="mb-40"
                                          style={{
                                            marginTop: "10px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <ul>
                                            <li>
                                              <span>{"\u2022"}</span> 24/7 Tech
                                              Support
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Advance Plan{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Import/Export Product (coming soon){" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Upto 2000 Products per Month{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  default:
                                    return (
                                      <div className="pricing-single-content">
                                        <div className="mb-40">
                                          <span className="price-icon-sign">
                                            <i className="fa fa-dollar"></i>
                                          </span>
                                          <span className="main-price">
                                            {item.minPrice}
                                          </span>
                                          <span className="sub-price">
                                            {item.subPrice}
                                          </span>
                                          <span className="main-price-info">
                                            {item.packageTime}
                                          </span>
                                        </div>
                                        <div className="center-block">
                                          <a
                                            type="button"
                                            className="softo-btn-outline"
                                            href="https://app.custommax.net/pricing"
                                          >
                                            Buy Now
                                          </a>
                                        </div>
                                        <div
                                          className="mb-40"
                                          style={{
                                            marginTop: "10px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <ul>
                                            <li>
                                              <span>{"\u2022"}</span> 24/7 Tech
                                              Support{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Limit 30
                                              Orders per Month{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Upto 30
                                              Products per Month{" "}
                                            </li>
                                            <li>
                                              <span>{"\u2022"}</span> Fulfill
                                              automatically{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                }
                              })()}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* end row */}
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-md-12">
                        <div className="section-heading text-center">
                          <h3 style={{ fontSize: "45px", color: "blueviolet" }}>
                            All Plans Include
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ fontSize: "18px", textAlign: "left" }}
                    >
                      <div className="col-md-12">
                        <div className="col-md-4">
                          <ul>
                            <li>
                              <span>{"\u2022"}</span> Upload PSD file
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              <span>{"\u2022"}</span> Export CSV files
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              <span>{"\u2022"}</span> Free Clipart Template
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <ul>
                            <li>
                              <span>{"\u2022"}</span> Upload unlimited font and
                              mockup
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              <span>{"\u2022"}</span> Upload unlimited color
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              <span>{"\u2022"}</span> Flexible text editing
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <ul>
                            <li>
                              <span>{"\u2022"}</span> Set up the first campaign
                              by Custom Max Supporter
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              <span>{"\u2022"}</span> Free Tech Support (24/7)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end pricing */}
      </div>
    );
  }
}

export default Pricing;
