import React, { Component } from "react";
import { Link } from "react-router-dom";
const initData = {
  heading: "Do you find something?",
  content: "Read more on our blog to get further information!",
};

const blogData = [
  {
    image: "/img/customax.jpg",
    heading: "Let's prepare PSD file with us!",
    content: "",
    link: "https://www.youtube.com/watch?v=6_LsGCtNT5Q",
  },
  {
    image: "/img/customax2.jpg",
    heading: "It's easy to create the shirt mockup",
    content: "",
    link: "https://www.youtube.com/watch?v=gH72YTRDJLI",
  },
  {
    image: "/img/customax3.jpg",
    heading: "How to get the mockup for 2 tumblers",
    content: "",
    link: "https://www.youtube.com/watch?v=qMkmsAlYTcU",
  },
];

class Blog extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    /**
        you can do this by make a api call
        axios.get(`${URL}`)
            .then(res => {
                this.setState({
                    initData: res.data,
                    data: res.data....
                })
            })
            .catch(err => console.log(err))
        */
    this.setState({
      initData: initData,
      data: blogData,
    });
  }
  render() {
    return (
      <section id="news" className="ptb-90 bg-secondary">
        <div className="blog-section-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading text-center">
                  <h2>{this.state.initData.heading}</h2>
                  <h5>{this.state.initData.content}</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div id="sliderBlog" className="owl-carousel">
                {this.state.data.map((data, idx) => {
                  return (
                    <div key={`blog_${idx}`} className="single-blog-article">
                      <div className="blog-post-img-thumb">
                          <img src={data.image} alt="" />
                          <div className="video-play-button">
                            <a
                              href={data.link}
                              className="video video-play mfp-iframe"
                              data-toggle="modal"
                              data-target="#video-popup"
                            >
                              <span className="ti-control-play"></span>
                            </a>
                          </div>
                      </div>
                      <div className="blog-post-content-area">
                        <h3>
                          <Link to="/blog-details/" target="_blank">
                            {data.heading}
                          </Link>
                        </h3>
                        <p>{data.content}</p>
                        <div className="blog-footer-area"></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog;
