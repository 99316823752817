import React, { Component } from "react";
import axios from "axios";

// const BASE_URL =
//   "https://my-json-server.typicode.com/lhmisho/jsonserver/themeOneFaqSection";
class Faq extends Component {
  state = {
    initData: {
      heading: "Frequently Asked Questions",
      content:
        "Need more help? Check out the documentation or report an issue to us!",
      image: "",
    },
    data: [
      {
        id: 1,
        question:
          "Is there a test store inside Shopify to see how the tool works?",
        answer:
          "Yes, you can see some sample products on our Shopify test store: https://mtee-2.myshopify.com/collections/sample-products",
      },
      {
        id: 2,
        question: "Can I try App before I buy?",
        answer:
          "You sure can. You can use Custommax App with full functions within 14 free-trial before purchasing.",
      },
      {
        id: 3,
        question: "How I can launch a product?",
        answer:
          "There are mainly 6 steps. Create your product on Shopify, then add this product to the Campaign, Creating options, Uploading PSD files, and finally adding Mockup for the product. You can read more {linkDoc}",
      },
      {
        id: 4,
        question:
          "Is there any way to help me create many campaigns which have the same design but different products?",
        answer:
          "Yes, you can use the duplicated campaign function for this case. It just takes 2 seconds to copy the campaign. It is really easy and fast, right?",
      },
    ],
  };

  // componentDidMount() {
  //   axios
  //     .get(`${BASE_URL}`)
  //     .then((res) => {
  //       this.setState({
  //         initData: res.data,
  //         data: res.data.data,
  //       });
  //       // console.log(this.state)
  //     })
  //     .catch((err) => console.log(err));
  // }

  render() {
    var linkDoc = <a href="#news">here</a>;
    return (
      <section id="faqs" className="faq-section ptb-90">
        <div className="faq-section-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {/* <div className="faq-img">
                  <img
                    src={this.state.initData.image}
                    className="img-responsive"
                    alt=""
                  />
                </div> */}
                <div className="section-heading">
                  <h2>{this.state.initData.heading}</h2>
                  <h5>
                    Need more help? Check out the{" "}
                    <a href="https://drive.google.com/drive/folders/1y1C4ReCNjWioFMqVg2DKvYSXkrzStU1D">
                      documentation
                    </a>{" "}
                    or report an issue to us!
                  </h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="panel-group" id="accordion">
                  {/* Start Single Item */}
                  {this.state.data.map((item, idx) => {
                    return (
                      <div key={`faq_${idx}`} className="panel panel-default">
                        <div className="panel-heading" id={`heading_${idx}`}>
                          <h4 className="panel-title">
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href={`#collapse_${idx}`}
                              className="collapsed"
                            >
                              {item.question}
                            </a>
                          </h4>
                        </div>
                        <div
                          id={`collapse_${idx}`}
                          className="panel-collapse collapse"
                        >
                          <div className="panel-body">
                            {(() => {
                              switch (item.id) {
                                case 1:
                                  return (<p>Yes, you can see some sample products on our Shopify test<a href="https://mtee-2.myshopify.com/collections/sample-products"> store</a></p>);
                                case 3:
                                  return (<p>There are mainly 6 steps. Create your product on Shopify, then add this product to the Campaign, Creating options, Uploading PSD files, and finally adding Mockup for the product. You can <a href="https://drive.google.com/drive/folders/1y1C4ReCNjWioFMqVg2DKvYSXkrzStU1D?usp=sharing">read more</a></p>);
                                default:
                                  return (<p>{item.answer}</p>);
                              }
                            })()}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* End Single Item */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Faq;
