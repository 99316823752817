import React from "react";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import Filter4Icon from "@material-ui/icons/Filter4";
import Filter5Icon from "@material-ui/icons/Filter5";
import Filter6Icon from "@material-ui/icons/Filter6";
class Feature extends React.Component {
  state = {
    data: {
      content: "How to launch a campaign",
      heading: "Custom Max makes the process simple, smooth, and intuitive",
      image: "/img/ip_custommax.png",
    },
    featureDataOne: [
      {
        id: 1,
        heading: "Step 1: Create product base on Shopify",
        content: "",
      },
      {
        id: 2,
        heading: "Step 2: Add this product to App’s Campaign",
        content: "",
      },
      {
        id: 3,
        heading: "Step 3: Create options for the product on App",
        content: "",
      },
    ],
    featureDataTwo: [
      {
        id: 1,
        heading: "Step 4: Upload PSD file and map layers together",
        content: "",
      },
      {
        id: 2,
        heading: "Step 5: Edit the product’s design on Demo Tab",
        content: "",
      },
      {
        id: 3,
        heading: "Step 6: Add mockup for this product",
        content: "",
      },
    ],
  };

  render() {
    return (
      <div>
        {/* start features section */}
        <section id="features" className="bg-secondary ptb-90">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading text-center">
                  <h2>{this.state.data.heading}</h2>
                  <h5>{this.state.data.content}</h5>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6">
                <div className="single-feature mb-5">
                  <div className="feature-icon">
                    <div className="icon icon-shape bg-color white-text">
                      <Filter1Icon style={{ color: "blueviolet" }} />
                    </div>
                  </div>
                  <div className="feature-content">
                    <h5>Step 1: Create product base on Shopify</h5>
                  </div>
                </div>
                <div className="single-feature mb-5" style={{marginTop: '180px'}}>
                  <div className="feature-icon">
                    <div className="icon icon-shape bg-color white-text">
                      <Filter2Icon style={{ color: "blueviolet" }} />
                    </div>
                  </div>
                  <div className="feature-content">
                    <h5>Step 2: Add this product to App’s Campaign</h5>
                  </div>
                </div>
                <div className="single-feature mb-5" style={{marginTop: '180px'}}>
                  <div className="feature-icon">
                    <div className="icon icon-shape bg-color white-text">
                      <Filter3Icon style={{ color: "blueviolet" }} />
                    </div>
                  </div>
                  <div className="feature-content">
                    <h5>Step 3: Create options for the product on App</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 hidden-sm hidden-xs">
                <div className="feature-image">
                  <img
                    src={this.state.data.image}
                    className="pos-hcenter img-responsive"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="single-feature mb-5">
                  <div className="feature-icon">
                    <div className="icon icon-shape bg-color white-text">
                      <Filter4Icon style={{ color: "blueviolet" }} />
                    </div>
                  </div>
                  <div className="feature-content">
                    <h5>Step 4: Upload PSD file and map layers together</h5>
                  </div>
                </div>
                <div className="single-feature mb-5" style={{marginTop: '180px'}}>
                  <div className="feature-icon">
                    <div className="icon icon-shape bg-color white-text">
                      <Filter5Icon style={{ color: "blueviolet" }} />
                    </div>
                  </div>
                  <div className="feature-content">
                    <h5>Step 5: Edit the product’s design on Demo Tab</h5>
                  </div>
                </div>
                <div className="single-feature mb-5" style={{marginTop: '180px'}}>
                  <div className="feature-icon">
                    <div className="icon icon-shape bg-color white-text">
                      <Filter6Icon style={{ color: "blueviolet" }} />
                    </div>
                  </div>
                  <div className="feature-content">
                    <h5>Step 6: Add mockup for this product</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end features section */}
      </div>
    );
  }
}

export default Feature;
